import React, { useEffect } from "react";

const ShippingPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="home-main">
        <div className="home2">
          <div className="home21">
            <div className="home2121">
              <div className="text">
                <h3>Shipping Policy</h3>
              </div>
              <p>
                At BOOKSONFLY, we prioritize delivering an exceptional shopping
                experience, extending from selecting your favorite reads to
                ensuring timely and secure delivery to your doorstep. Our
                Shipping Policy outlines the procedures and commitments involved
                in delivering your literary treasures promptly and efficiently.
              </p>
              <div style={{ marginLeft: 20 }}>
                <br />
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    {" "}
                    Order Processing: Upon placing an order on
                    www.booksonfly.com, our team initiates swift processing.
                    Orders are typically processed within 24 to 48 hours on
                    business days. You'll receive an email confirmation
                    containing order details and an estimated delivery
                    timeframe.{" "}
                  </p>
                </p>
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    {" "}
                    Shipping Partners: BOOKSONFLY collaborates with reputable
                    shipping partners dedicated to reliable and secure transit
                    services. Our partnerships ensure your packages reach you in
                    excellent condition and within the specified timeframe.
                  </p>
                </p>
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    {" "}
                    Shipping Rates and Timeframes: Shipping rates and delivery
                    times vary based on your location, selected shipping method,
                    and the size/weight of your order. During checkout, you'll
                    find available shipping options, each with corresponding
                    rates and estimated delivery timelines.
                  </p>
                </p>
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    {" "}
                    Tracking Information: Once your order is dispatched, a
                    shipping confirmation email including tracking information
                    is sent. This enables you to monitor your package's journey
                    until it reaches your doorstep, ensuring you're informed
                    every step of the way.
                  </p>
                </p>
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    {" "}
                    International Shipping: BOOKSONFLY caters to global book
                    enthusiasts. International shipping options with estimated
                    delivery times and associated costs are available. Note that
                    additional customs duties or taxes may apply based on your
                    country's regulations.
                  </p>
                </p>
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    {" "}
                    Customer Support: Our dedicated customer service team is
                    available to assist with any queries or concerns regarding
                    your order, shipping, or any aspect of your BOOKSONFLY
                    journey. Reach out via email, phone, or our website's live
                    chat feature for prompt assistance.
                  </p>
                </p>
                <br />
              </div>
              <p>
                BOOKSONFLY's Shipping Policy aims to guarantee that your
                literary discoveries reach you expediently and in impeccable
                condition. We are committed to providing an exceptional shopping
                journey, delivering not just books but an unparalleled
                experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingPolicy;
