import React, { useEffect } from "react";

const ReturnRefundPolicy = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="home-main">
        <div className="home2">
          <div className="home21">
            <div className="home2121">
              <div className="text">
                <h3>Return & Refund Policy</h3>
              </div>
              <p>
                A product is returnable within the applicable return window if
                you’ve received it in a condition that is physically damaged,
                has missing parts or accessories, is defective, or different
                from its description on the product detail page on BOOKSONFLY.
              </p>
              <br />
              <div style={{marginLeft: 20}}>
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                <p> ❑  </p><p style={{marginLeft: 30}}> Refund Process: Upon receipt and inspection of the returned
                item(s), BOOKSONFLY will initiate the refund process. Refunds
                are typically processed using the original payment method and
                may take a few business days to reflect in your account. </p>
              </p>
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                <p> ❑  </p><p style={{marginLeft: 30}}> We will process a return only if: we determine that the
                product was not damaged while in your possession; the product is
                not different from what we shipped to you; you return the
                product in its original condition (with the
                brand’s/manufacturer's box, MRP tag intact, user manual,
                warranty card and all the accessories therein).
                </p>
              </p>
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                <p> ❑  </p><p style={{marginLeft: 30}}> Returns may not be accepted in certain instances, including
                cases where the incorrect model, colour, or product has been
                ordered. It is essential for customers to carefully review and
                confirm their orders before finalizing purchases to ensure
                accurate product selection.
                </p>
              </p>
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                <p> ❑  </p><p style={{marginLeft: 30}}> You cannot return products marked as "non-returnable" on the
                product detail page.
                </p>
              </p>
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                <p> ❑  </p><p style={{marginLeft: 30}}> You do not require additional information to return an
                eligible order, unless otherwise noted in the category-specific
                policy.
                </p>
              </p>
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                <p> ❑  </p><p style={{marginLeft: 30}}> Customer Support: Our customer service team is dedicated to
                assisting you with any concerns or queries regarding returns,
                refunds, or any other aspect of your BOOKSONFLY experience.
                Contact us via email, phone, or our website's live chat for
                prompt assistance.
                </p>
              </p>
              </div>
              <br />
              <p>
                BOOKSONFLY's Return & Refund Policy is designed to ensure a
                seamless process in the event of dissatisfaction or issues with
                your order. We aim to uphold our commitment to providing
                exceptional service and guarantee your contentment with every
                purchase.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReturnRefundPolicy;