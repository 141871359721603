import React, { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useMain } from "../hooks/useMain";
import { RotatingLines } from "react-loader-spinner";

const columns = [
  {
    name: <h4 className="text-lg font-bold">Name</h4>,
    selector: (row) => <p className="text-base">{row.name}</p>,
    sortable: true,
  },
  {
    name: <h4 className="text-lg font-bold">Email</h4>,
    selector: (row) => <p className="text-base">{row.email}</p>,
    sortable: true,
  },
  {
    name: <h4 className="text-lg font-bold">Phone</h4>,
    selector: (row) => <p className="text-base">{row.phone}</p>,
    sortable: true,
  },
];

const Admin = () => {
  const { getUsers } = useMain();

  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [laodFlag, setLaodFlag] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const [value, setValue] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(value.email);
    setLaodFlag(true);

    if (
      value.email == "gauravprakash1131@gmail.com" &&
      value.password == "Gaurav@123"
    ) {
      setFlag(true);
    } else {
      setFlag(false);
      alert("Incorrect Email or Password");
    }
    setLaodFlag(false);
  };

  const getData = async () => {
    setFlag(false);
    let ans = await getUsers();
    console.log(ans);
    setData(ans.data);
    setFlag(false);
  };

  return (
    <>
      <div className="admin-main">
        <div className="admin-main1 py-6 px-14">
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                value={value.email}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Email"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                value={value.password}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>

            <button
              type="submit"
              className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"
            >
              {laodFlag ? (
                <RotatingLines
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              ) : (
                "Submit"
              )}
            </button>
          </form>
          {flag ? (
            <DataTable
              columns={columns}
              data={data}
              striped={true}
              title={"Registerd Users"}
              pagination
            />
          ) : (
            <div className="text-center flex justify-center">
              {" "}
              <RotatingLines
                strokeColor="black"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={true}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Admin;
