import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
// import insta from '../../../public/images/instaicon.png';
// import fb from '../../../public/images/fbicon.png';
// import lin from '../../../public/images/linkedinicon.webp';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer1 flex justify-between">
          {/* <div className="foot2 foot0">
            <h5>Quick Links</h5>
            <p
              onClick={() => {
                document
                  .getElementById("registerModal")
                  .classList.toggle("hidden");
              }}
            >
              Home
            </p>
            <p
              onClick={() => {
                document
                  .getElementById("registerModal")
                  .classList.toggle("hidden");
              }}
            >
              About Us
            </p>
            <p
              onClick={() => {
                document
                  .getElementById("registerModal")
                  .classList.toggle("hidden");
              }}
            >
              FAQs
            </p>
            <p
              onClick={() => {
                document
                  .getElementById("registerModal")
                  .classList.toggle("hidden");
              }}
            >
              Blogs
            </p>
          </div> */}
          <div className="foot3 foot0">
            <div className="footer-card">
              <h5 className="footer-title">Follow Us</h5>
              <h5 className="footer-text">
                {" "}
                On &nbsp; Our &nbsp; Social &nbsp; Network
              </h5>
            </div>
            <div className="flex md:gap-3 gap-7 md:max-w-[420px] max-w-[242px] justify-around">
              <p
                style={{ display: "flex", marginBottom: "0",}}
              >
                <a
                  className="footer-link-icon"
                  href="https://www.instagram.com/booksonfly.official/"
                  target="_blank" rel="noopener noreferrer"
                >
                <FontAwesomeIcon icon={faInstagram} />
                </a>
              </p>
              <p
                style={{ display: "flex", marginBottom: "0",}}
              >
                <a
                  className="footer-link-icon"
                  href="http://www.facebook.com/booksonfly"
                  target="_blank" rel="noopener noreferrer"
                >
                <FontAwesomeIcon icon={faFacebookF} />
                 
                </a>
              </p>
              <p style={{ display: "flex", marginBottom: "0" }} >
                <a
                  className="footer-link-icon"
                  href="https://linkedin.com/company/booksonfly"
                  target="_blank" rel="noopener noreferrer"
                >
                
                <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </p>
              <p
                style={{ display: "flex", marginBottom: "0", fontSize: "46px" }}
              >
                <a
                  className="footer-link-icon"
                  href="https://www.booksonfly.com/"
                  target="_blank" rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faGlobe} />
                </a>
              </p>
            </div>
          </div>
          <div className="foot1 foot0">
            <h5  className="footer-link-title">Customer Policies</h5>
            <Link to="/privacy-policy">
              <p>Privacy Policy</p>
            </Link>
            <Link to="/terms-conditions">
              <p>Terms & Conditions</p>
            </Link>
            <Link to="/return-refund-policy">
              <p>Return & Refund</p>
            </Link>
            <Link to="/shipping-policy">
              <p>Shipping</p>
            </Link>
            <Link to="/shipping-policy">
              <p>FAQ</p>
            </Link>
            {/* <p
              onClick={() => {
                document
                  .getElementById("registerModal")
                  .classList.toggle("hidden");
              }}
            >
              Privacy Policy
            </p> */}
            {/* <p
              onClick={() => {
                document
                  .getElementById("registerModal")
                  .classList.toggle("hidden");
              }}
            >
              Terms & Conditions
            </p> */}
            {/* <p
              onClick={() => {
                document
                  .getElementById("registerModal")
                  .classList.toggle("hidden");
              }}
            >
              Return & Refund
            </p> */}
            {/* <p
              onClick={() => {
                document
                  .getElementById("registerModal")
                  .classList.toggle("hidden");
              }}
            >
              Shipping
            </p> */}
          </div>
        </div>
        <div
          className="w-fit mx-auto"
          style={{ color: "#fff", marginTop: -25, paddingBottom: 10 }}
        >
          BOOKSONFLY © 2023. ALL RIGHTS RESERVED
        </div>
      </footer>
    </>
  );
};

export default Footer;
