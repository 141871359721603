import React, { useEffect } from "react";
import RegisterModal from "../Modals/RegisterModal";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const Home = ({ notify }) => {
  const navigate = useNavigate();

  return (
    <>
      <RegisterModal notify={notify} />
      <div className="home-main">
        <HeroSlider />
        <div className="home2">
          <div className="home21 flex items-center">
            <div className="home211">
              <img src="/images/p4.png" alt="" />
            </div>
            <div className="home212">
              <div className="home2121">
                <div className="text">
                  <h3>About Us</h3>
                </div>
                <p>
                  Booksonfly is an online marketplace for intellectual content
                  including Books, Files, Text, Audio, Video and Premium
                  Stationary.
                </p>
                <p>
                  You are welcome to join as a member to the platform, where one
                  can engage in Intellectual conversation, purchase usefull
                  stuffs, and also market your own content to other members.
                </p>
              </div>
              <div className="home2122 flex">
                <div className="home2-card">
                  <div className="home2-card2">
                    <div className="home2-card1 absolute"></div>
                    <h4>Vision</h4>
                    <p>
                      To become the premier global platform, uniting
                      intellectuals worldwide for seamless sharing, learning and
                      growth.
                    </p>
                  </div>
                </div>

                <div className="home2-card relative">
                  <div className="home2-card1 absolute"></div>
                  <div className="home2-card2">
                    <h4>Mission</h4>
                    <p>
                      To create a learner centric community where members can
                      access intellectual content conveniently.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home3">
          <div className="home31">
            <div className="home311 flex items-center justify-center">
              <div className="home3111">
                <div className="text">
                  <h3>Our Products</h3>
                </div>
              </div>
              <div className="home3112">
                <img src="/images/p8.png" alt="" />
              </div>
            </div>
            <div className="home312 flex justify-between">
              <div onClick={() => navigate("register")} className="home3121">
                <img src="/images/p14.png" alt="" />
                <div className="text">
                  <p className="text-center">New Books</p>
                </div>
              </div>
              <div onClick={() => navigate("register")} className="home3121">
                <img src="/images/p16.png" alt="" />
                <div className="text">
                  <p className="text-center">Old Books</p>
                </div>
              </div>
              <div onClick={() => navigate("register")} className="home3121">
                <img src="/images/p13.png" alt="" />
                <div className="text">
                  <p className="text-center">Digital Books</p>
                </div>
              </div>
              <div onClick={() => navigate("register")} className="home3121">
                <img src="/images/p15.png" alt="" />
                <div className="text">
                  <p className="text-center">Stationary</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

function HeroSlider() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <div className="home1" style={{ background: "#ffffff" }}>
            <div className="home11 text-center">
              {/* <div className="home111">
                <h1>BUY & SELL</h1>
              </div> */}
              <div className="home112 flex justify-center">
              <img src="/images/product/product1.png" width={"1600px"} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="home1" style={{ background: "#fdf0cd" }}>
            <div className="home11 text-center">
              {/* <div className="home111">
                <h1>BUY & SELL</h1>
              </div> */}
              <div className="home112 flex justify-center">
              <img src="/images/product/product2.png" width={"1600px"} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="home1" style={{ background: "#ffffff" }}>
            <div className="home11 text-center">
              {/* <div className="home111">
                <h1>BUY & SELL</h1>
              </div> */}
              <div className="home112 flex justify-center">
              <img src="/images/product/product3.png" width={"1600px"} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="home1" style={{ background: "#ffffff" }}>
            <div className="home11 text-center">
              {/* <div className="home111">
                <h1>BUY & SELL</h1>
              </div> */}
              <div className="home112 flex justify-center">
              <img src="/images/product/product4.png" width={"1600px"} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="home1" style={{ background: "#fdf0cd" }}>
            <div className="home11 text-center">
              {/* <div className="home111">
                <h1>BUY & SELL</h1>
              </div> */}
              <div className="home112 flex justify-center">
                <img src="/images/product/product5.png" width={"1600px"} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="home1" style={{ background: "#ffffff" }}>
            <div className="home11 text-center" style={{ paddingBottom: "0" }}>
              {/* <div className="home111">
                <h1>BUY & SELL</h1>
              </div> */}
              <div className="home112 flex justify-center">
              <img src="/images/product/product6.png" width={"1600px"} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}
