import React, { useEffect } from "react";
import RegisterModal from "../Modals/RegisterModal";

const TermsCond = () => {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="home-main">
        <div className="home2">
          <div className="home21">
            <div className="home2121">
              <div className="text">
                <h3>Terms & Conditions</h3>
              </div>
              <p>
                Welcome to BOOKSONFLY! Your use of www.booksonfly.com implies
                your acceptance of the following terms and conditions:
              </p>
              <div style={{marginLeft: 20}}>
<br />
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                 <p> ❑  </p><p style={{marginLeft: 30}}> Website Usage: By accessing our website, you agree to use it
                for lawful purposes and in compliance with all applicable laws
                and regulations. Any unauthorized use or modification of the
                site's content is strictly prohibited.</p>
              </p>
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                 <p> ❑  </p><p style={{marginLeft: 30}}> Intellectual Property: All content, including text, graphics,
                logos, and images on BOOKSONFLY, is protected by intellectual
                property laws. You may not reproduce, distribute, or use any
                content without explicit permission.</p>
              </p>
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                 <p> ❑  </p><p style={{marginLeft: 30}}> Product Information: While we strive to ensure accuracy,
                product descriptions, prices, and availability on BOOKSONFLY may
                occasionally contain errors. We reserve the right to rectify
                inaccuracies and modify information without prior notice.
                </p>
              </p>
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                 <p> ❑  </p><p style={{marginLeft: 30}}> Payment and Pricing: Prices for products on BOOKSONFLY are
                listed in the applicable currency and include relevant taxes
                unless stated otherwise. Payment is processed upon order
                placement.</p>
              </p>
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                {" "}
                 <p> ❑  </p><p style={{marginLeft: 30}}> Shipping and Delivery: Delivery times and costs may vary based
                on location and shipping method. BOOKSONFLY is not liable for
                delays caused by shipping carriers or unforeseen circumstances.
              </p></p>
              <p style={{display: 'flex', fontWeight: '100', color: '#000'}}>
                 <p> ❑  </p><p style={{marginLeft: 30}}> Modifications: BOOKSONFLY reserves the right to modify,
                update, or terminate these terms and conditions at any time
                without prior notice. Continued use of the website constitutes
                acceptance of the modified terms.</p>
              </p>
              </div>
              <br />
              <p>
                Please carefully review these terms and conditions before using
                www.booksonfly.com. Your continued use of the site implies your
                agreement to these terms. If you have any questions or concerns,
                please contact our customer support team for clarification.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsCond;