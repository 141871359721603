import React, { useEffect } from "react";
import RegisterModal from "../Modals/RegisterModal";

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="home-main">
        <div className="home2">
          <div className="home21">
            <div className="home2121">
              <div className="text">
                <h3>Privacy Policy</h3>
              </div>
              <p>
                Protecting your privacy and ensuring the security of your
                personal information is paramount at BOOKSONFLY. This Privacy
                Policy outlines our commitment to safeguarding your data when
                you visit www.booksonfly.com.
              </p>
              <div style={{ marginLeft: 20 }}>
                <br />
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    
                    Information Collection: When you browse our website or make
                    a purchase, we may collect personal information such as your
                    name, email address, shipping address, and payment details.
                    This data is securely stored and used solely for order
                    processing and improving your shopping experience.
                  </p>
                </p>
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    Usage of Information: Your information is utilized to
                    fulfill orders, provide customer support, personalize your
                    shopping experience, and communicate relevant updates such
                    as order confirmations or promotional offers. We do not sell
                    or share your data with third parties for marketing purposes
                    without your consent.
                  </p>
                </p>
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    Cookies and Tracking Technology: BOOKSONFLY employs cookies
                    and similar tracking technologies to enhance website
                    functionality, analyze trends, and customize content. These
                    tools help us understand user preferences and improve our
                    services.
                  </p>
                </p>
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    Data Security: We employ industry-standard security measures
                    to protect your information from unauthorized access,
                    alteration, or disclosure. Secure Socket Layer (SSL)
                    encryption technology safeguards data during transmission.
                  </p>
                </p>
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    Third-Party Links: Our website may contain links to
                    third-party sites. Please note that BOOKSONFLY  is not
                    responsible for the privacy practices or content of these
                    external websites. We encourage users to review the privacy
                    policies of these sites when navigating away from our
                    platform.
                  </p>
                </p>
                <p
                  style={{ display: "flex", fontWeight: "100", color: "#000" }}
                >
                  <p> ❑ </p>
                  <p style={{ marginLeft: 30 }}>
                    
                    Consent: By using www.booksonfly.com, you consent to the
                    collection and use of your information in accordance with
                    this Privacy Policy.
                  </p>
                </p>
              </div>
              <br />
              <p>
              BOOKSONFLY  values your trust and endeavors to maintain
                transparency regarding the collection, usage, and protection of
                your data. If you have any queries or concerns about our Privacy
                Policy, please contact our dedicated customer support team for
                assistance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
